@font-face {
    font-family: Technoma;
    src: url("../assets/Technoma.otf");
}

html,
body {
    min-height: 100%;
}

#root {
    height: 100%;
}

.page-container {
    height: 100%;
    width: 100%;
}

body {
    height: 100%;
}

/* Style for Navigation Bar */
.main-header {
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 60px;
    background-color: #3271fc;
    box-sizing: border-box;
}

.main-nav {
    width: calc(100% - 90px);
    height: 60px;
    display: inline-block;
    vertical-align: middle;
}

.main-nav__items {
    padding-left: 0;
}

.main-nav__item {
    font-family: Technoma;
    font-size: 1.5rem;
    font-weight: lighter;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 20px;
}

.main-nav__item > a {
    text-decoration: none;
    color: white;
}

.main-nav__item > a:hover {
    color: rgb(0, 0, 0);
}

.hamburger-icon-container {
    vertical-align: middle;
    display: inline-block;
    height: 60px;
    width: 90px;
    visibility: hidden;
}

.icon-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

#hamburger-icon {
    width: 60%;
    height: 60%;
    margin: auto;
    display: block;
    color: white;
}

.hamburger-nav {
    display: none;
}

.hamburger-nav__items {
    list-style: none;
    margin: 0;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: rgb(29, 29, 29);
}

.hamburger-nav__item {
    width: 100%;
    display: block;
    padding: 5px 0px;
    border-bottom: 2px solid #3271fc;
}

.hamburger-nav__item:last-of-type {
    border-bottom: 0;
}

.hamburger-nav__item > a{
    text-decoration: none;
    display: block;
    color: white;
    font-family: Technoma;
    font-size: 1.5rem;
    font-weight: lighter;
}

/* Style for Main Content */
.main-content {
    min-height: calc(100vh - 110px);
    margin-top: 60px;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
}

/* Style for Footer */
.main-footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: rgb(29, 29, 29);
}

.main-footer h3 {
    width: 100%;
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

/* Other */
#submit,
#submit:hover,
#submit:active,
button,
button:hover,
button:active {
    font-family: Arial, sans-serif;
    font-size: medium;
    font-weight: 600;
    min-height: 40px;
    cursor: pointer;
}

.shrink-animation {
    transition: transform .2s; /* Animation */
}

.shrink-animation:hover {
    transform: scale(0.95);
}

@media (max-width: 700px) {
    .remove-nav-item {
        display: none !important;
    }
    .hamburger-icon-container {
        visibility: visible;
    }
}

/* Smartphones */
@media (max-width: 466px) {
    .shrink-animation,
    .shrink-animation:hover {
        transform: none;
    }
}


