.disclaimer {
    background: url("../assets/bg-image.jpg");
    background-size: cover;
    color: white;
    text-align: center;
}

.disclaimer a {
    color: #c6b340;
}
