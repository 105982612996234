#hotkey-editor {
    width: 650px;
    margin: auto;
    padding: 20px;
    border-radius: 5px;
    background-color: rgb(225, 225, 225);
    position: relative;
}

#hotkey-editor h2 {
    margin-top: 0;
    margin-bottom: 10px;
}

#close-icon {
    position: absolute;
    top: 25px;
    right: 20px;
    box-sizing: border-box;
    padding: 7px;
    height: 32px;
    width: 32px;
    background-color: white;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.125);
    border-radius: 3px;
    cursor: pointer;
}

#close-icon:hover {
    background-color: red;
    color: white;
}

.hotkey-editor-instructions {
    margin-top: 0;
}

/* Hotkey Editor Table */
.hotkey-editor-table {
    width: 100%;
    border-spacing: 0;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    margin-bottom: 25px;
}

.hotkey-editor-table td {
    width: 50%;
    padding: 0;
}

.hotkey-editor-table tr {
    display: block;
    width: 100%;
}

.hotkey-editor-table tr:hover {
    color: blue;
    font-weight: bold;
    cursor: pointer;
}

.hotkey-editor-table td:first-of-type tr:nth-of-type(odd),
.hotkey-editor-table td:last-of-type tr:nth-of-type(even) {
    background-color: white;
}

.hotkey-editor-table td:first-of-type tr:first-of-type {
    border-radius: 5px 0px 0px 0px;
}
.hotkey-editor-table td:first-of-type tr:last-of-type {
    border-radius: 0px 0px 0px 5px;
}
.hotkey-editor-table td:last-of-type tr:first-of-type {
    border-radius: 0px 5px 0px 0px;
}
.hotkey-editor-table td:last-of-type tr:last-of-type {
    border-radius: 0px 0px 5px 0px;
}

.hotkey-editor-table .hotkey-name,
.hotkey-editor-table .hotkey {
    display: inline-block;
    box-sizing: border-box;
    pointer-events: none;
}

.hotkey-editor-table .hotkey-name {
    width: calc(100% - 150px);
    padding-left: 15px;
}

.hotkey-editor-table .hotkey {
    width: 150px;
    text-align: right;
    padding-right: 25px;
}

/* Hotkey Editor Warning */
.hotkey-editor-warning {
    color: red;
    margin-bottom: 25px;
}

.hotkey-editor-warning ul,
.hotkey-editor-warning p {
    margin: 0px;
}

/* Hotkey Editor Reset */
.hotkey-input-form input {
    width: 125px;
    height: 25px;
    border: 0;
    box-sizing: border-box;
}

.hotkey-editor-reset {
    margin: 10px 0px;
    height: 50px;
}

.hotkey-editor-reset select,
.hotkey-editor-reset button {
    display: inline-block;
    width: calc(33.33% - 6.66px);
    height: 100%;
    vertical-align: middle;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
}

.hotkey-editor-reset select {
    margin: 0px;
    text-align: center;
}

.hotkey-editor-reset button {
    border: 0;
    background-color: red;
    color: white;
}

.hotkey-editor-reset button:first-of-type {
    margin: 0px 10px;
}

/* Hotkey Editor Submit */
.hotkey-editor-save {
    height: 50px;
}

.hotkey-editor-save input,
.hotkey-editor-save button {
    border-radius: 5px;
    vertical-align: middle;
    display: inline-block;
    font-size: 1rem;
    border: 0;
    width: calc(50% - 5px);
    height: 100%;
    box-sizing: border-box;
}

.hotkey-editor-save input {
    margin-right: 5px;
    text-align: center;
}

.hotkey-editor-save button {
    margin-left: 5px;
    cursor: pointer;
    background-color: #3271fc;
    color: white
}

.hotkey-editor-save button:disabled {
    background-color: white;
    color:#3271fc;
    cursor: not-allowed;
}

.hotkey-editor-reset button:disabled {
    background-color: white;
    color:red;
    cursor: not-allowed;
}

#hotkey-editor p,
.hotkey-editor-table,
.hotkey-editor-table,
.hotkey-editor-reset select,
.hotkey-editor-save input,
.hotkey-editor-warning {
    font-size: 14px;
}

.hotkey-editor-reset, 
.hotkey-editor-save {
    height: 40px;
}