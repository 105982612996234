.logo-component {
    font-family: Technoma;
}

.logo-component h1 {
    margin: 0px;
    display: inline-block;
    letter-spacing: 3px;
}

.logo-component h1:first-of-type {
    color: #3271fc;
}

.logo-component h1:last-of-type {
    color: #c6b340;
}

@media (max-width: 450px) {
    .logo-component h1 {
        letter-spacing: 1px;
    }
}
