.feedback {
    background: url("../assets/bg-image.jpg");
    background-size: cover;
}

.feedback-form {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border-radius: 5px;
    background-color: rgb(225, 225, 225);
}

.feedback-form p {
    font-size: 14px;
}

.feedback-form #fname {
    box-sizing: border-box;
    width: calc(50% - 5px);
    background: white;
    padding: 20px 20px;
    border: none;
    border-radius: 5px;
    margin: 15px 10px 15px 0px;
    height: 47px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.125);
}

.feedback-form #lname {
    box-sizing: border-box;
    width: calc(50% - 5px);
    background: white;
    padding: 20px 20px;
    border: none;
    border-radius: 5px;
    height: 47px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.125);
}

.feedback-form h2 {
    text-align: center;
    color: black;
    margin: 0px;
}

.feedback-form .ratings {
    margin-bottom: 15px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.125);
    border-radius: 5px;
}

.feedback-form label {
    color: black;
    display: block;
    font-size: 14px;
    padding: 4px 0px;
}

.feedback-form label:hover {
    font-weight: 700;
    cursor: pointer;
}

.feedback-form label:first-of-type {
    border-radius: 5px 5px 0px 0px;
}

.feedback-form label:last-of-type {
    border-radius: 0px 0px 5px 5px;
}

.feedback-form label:nth-of-type(odd) {
    background-color: white;
}

.feedback-form .rating {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 10px 10px;
    vertical-align: middle;
}

.feedback-form #comment {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    background: white;
    font-size: large;
    padding: 20px 20px;
    border: none;
    border-radius: 5px;
    font-family: inherit;
    font-size: 14px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.125);
}

.feedback-form #submit {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 5px;
    background: #827717;
    color: white;
    font-weight: 600;
    transition: transform .2s; /* Animation */
}

.feedback-form #submit:hover {
    transform: scale(0.97);
}

.form-response {
    color: white;
    text-align: center;
}

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Smartphones */
@media (max-width: 466px) {
    .feedback-form #fname {
        width: 100%;
        margin-bottom: 10px;
    }
    .feedback-form #lname {
        width: 100%;
        margin-bottom: 30px;
    }
}
