.map {
    display: inline-block;
    margin-left: 30px;
    border-radius: 5px;
    position: absolute;
    left: 25%;
    outline-offset: -10px;
}

.villager-trainer-map {
    background: url("../assets/im-v5.jpg");
    background-size: 225%;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 35%;
    box-sizing: border-box;
}

.villager-trainer-map_building {
    position: relative;
    height: 100%;
    width: 100%;
    background-position: 50%;
    object-fit: cover;
    box-sizing: border-box;
    padding-top: 60px;
}

.villager-trainer-map_building_gray {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.building-selection-trainer-map {
    background-image: url("../assets/building-selection-map-v2.jpg");
    background-position: 75% 16.3%;
    background-size: 225%;
}

.selection-box {
    width: 100%;
    height: 100%;
    background-position: 75% 16.3%;
}

.map_crosshair {
    position: absolute;
    width: 35%;
    height: 0px;
    border: 2px solid black;
    bottom: 20%;
    left: calc(50% - 17.5%);
}

.map_crosshair_vert {
    position: absolute;
    height: 100%;
    border: 2px solid black;
    left: 50%;
}

.timer {
    border-radius: 0px 5px 0px 5px;
    background-color: black;
    position: absolute;
    left: calc(100% - 100px);
    top: 0%;
    height: 50px;
    width: 100px;
}

.timer h1 {
    padding: 0px;
    padding-bottom: 5%;
    text-align: center;
    width: 100%;
}

.building-prompt {
    width: 100%;
    margin-top: 30px;
    position: absolute;
}

.building-prompt > div {
    width: 30%;
    max-width: 215px;
    margin: auto;
    border-radius: 5px;
    background-color: rgb(225, 225, 225);
    padding: 10px;
    white-space: nowrap;
}

.building-prompt img {
    width: 125px;
    height: 125px;
    margin: auto;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.building-prompt h5 {
    min-width: calc(50% - 3px);
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: rgb(225, 225, 225);
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.125);
    border-radius: 3px;
    padding: 5px 0px;
    margin: 0px;
}

.building-prompt h5:first-of-type {
    margin-right: 6px;
}

.building-prompt-key-slots {
    width: 100%;
    height: 27px;
    vertical-align: middle;
}

.building-prompt-key-slots div {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    min-width: calc(50% - 3px);
    height: 100%;
    border-radius: 3px;
    background-color: #98999b;
}

.building-prompt-key-slots div:first-of-type {
    margin-right: 6px;
}

.hint {
    position: absolute;
    width: 100%;
    bottom: 0px;
    text-align: center;
    color: white;
    background-color: black;
    border-radius: 0px 0px 5px 5px;
    padding: 10px 0px;
}

.hint p {
    margin: 0px;
}

@media (max-height: 875px) {

    .building-prompt {
        margin-top: 15px;
    }

    .building-prompt > div{
        padding: 6px;
    }

    .building-prompt img {
        width: 85px;
        height: 85px;
        margin-bottom: 6px;
    }

    .building-prompt-key-slots {
        font-size: 13px;
        height: 25px;
    }
}