.home {
    background-image: url("../assets/bg-image.jpg");
    background-size: cover;
}

.intro {
    margin: auto;
    max-width: 850px;
}

.intro-content__text {
    line-height: 1.5;
    display: inline-block;
    margin: 0 0 0 auto;
}

.intro-content__text h1 {
    font-family: Technoma;
    font-size: 3rem;
    color: white;
}

.intro-content__text h3 {
    color: white;
    font-weight: 600;
}

.intro-content__text h3:first-of-type {
    margin-top: 0px;
}

.intro-content__text button {
    padding: 16px;
    border: none;
    border-radius: 5px;
    background: #827717;
    color: white;
    transition: transform .2s; /* Animation */
}

.intro-content__text button:hover {
    transform: scale(0.95);
}

.info {
    max-width: 850px;
    max-height: 500px;
    background-color: rgb(225, 225, 225);
    border-radius: 10px;
    margin: auto;
    margin-top: 25px;
    padding: 20px;
    box-sizing: border-box;
}

.info h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: black;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
}

.info > img {
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.emoji-container {
    display: inline-block;
}

.emoji {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

.hotkey-town {
    margin-top: 20px;
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 5px;
    object-position: 50% 10%;
}

@media (max-width: 500px) {
    .emoji-container {
        display: none;
    }

    .info h2 {
        display: block;
        margin: auto;
    }
}

@media (max-width: 450px) {
    .logo-component h1 {
        font-size: 65px;
    }
    .intro h3 {
        font-size: medium;
    }
    .info {
        padding: 15px;
    }
}
