.trainer {
    background-image: url("../assets/bg-image.jpg");
    background-size: cover;
}

.trainer-instructions-modal {
    width: 50%;
    min-width: 300px;
    max-width: 850px;
    margin: auto;
    background-color: rgb(29, 29, 29);
    border-radius: 10px;
    padding: 20px;
}

.trainer-instructions-modal h1 {
    color: white;
    text-align: center;
    margin: 0;
}

.trainer-instructions-modal p {
    color: white;
}

strong {
    color: #827717;
}

.shift {
    color: #3271fc;
}

.trainer-instructions-modal img {
    margin: auto;
    object-fit: contain;
    display: block;
}

.trainer-instructions-modal button {
    margin: auto;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    background: #827717;
    color: white;
    height: 50px;
    width: 100%;
    max-width: 400px;
    display: block;
    margin-bottom: 10px;
}

.trainer-selection {
    color: white;
    text-align: center;
    margin: 0px 0px 20px 0px;
}

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
}

.card-container {
    margin: auto;
    width: 75%;
    max-width: 1250px;
    min-width: 900px;
}

.trainer-card {
    border-radius: 10px;
    box-sizing: border-box;
    width: calc(50% - 20px);
    height: 100%;
    display: block;
    border-radius: 10px;
    background-color: rgb(225, 225, 225);
    padding: 20px;
    margin: auto;
}

.trainer-card-text {
    width: 100%;
    padding-bottom: 15px;
}

.trainer-card-text h2 {
    text-align: center;
    margin: 0px;
    color: black;
}

.trainer-card-text p {
    margin: 0px;
    padding: 0px 20px;
    padding-top: 5px;
    text-align: center;
    color: black;
}

.trainer-card img {
    width: 100%;
    height: 25vh;
    max-height: 400px;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.sound-selection:hover,
.hints-selection:hover,
.game-selection:hover,
.hotkey-selection select:hover {
    cursor: pointer;
}

.sound-container {
    margin: auto;
    height: 100%;
    width: 42px;
    pointer-events: none;
}

.sound-selection img {
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    margin: auto;
}

.timer h1 {
    color: white;
    text-align: center;
    margin: 0px;
    padding: 25px 0px;
}

#screen-too-small {
    display: none;
    color: white;
    text-align: center;
}

.trainer-container {
    width: 50%;
    min-width: 1150px;
    max-width: 1400px;
    min-height: 500px;
    box-sizing: content-box;
    margin: auto;
    position: relative;
}

.trainer-container .title-row {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 10px;
}

.trainer-title {
    color: white;
    margin: 0px;
    height: 100%;
}

.results-container {
    position: absolute;
    top: 5px;
    right: 0px;
    height: 100%;
}

.trainer-results {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 80%; 
    background-color: black;
    border-radius: 5px;
    margin: 0px;
}

.trainer-results h3{
    display: inline-block;
    margin: 0px;
    padding: 0px 20px;
    color: greenyellow;
}

.settings {
    background: rgb(225, 225, 225);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    width: 25%;
    min-width: 264px;
    display: inline-block;
    position: absolute;
}

.settings h2 {
    margin: 10px 0px;
}

.settings h2:first-of-type {
    margin-top: 0px;
}

.settings p {
    margin: 0px
}

select:hover {
    cursor: pointer;
}

.game-menu,
.hints-menu,
.sound-menu {
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.125);
    border-radius: 5px;
}

.game-menu {
    height: 94px;
    margin-bottom: 15px;
}

.game-menu select,
.game-menu p {
    width: 100%;
}

.game-menu select {
    margin: 0px;
    height: 50%;
    border: none;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
}

.game-menu p {
    box-sizing: border-box;
    margin: 14px 0px;
    text-align: center;
    color: blue;
}

.game-menu p:hover {
    cursor: pointer;
    text-decoration: underline;
}

.hints-menu,
.sound-menu {
    height: 47px;
}

.hints-menu {
    margin-bottom: 15px;
}

.hints-menu p,
.sound-menu p {
    margin: 14px 0px;
    width: 50%;
    vertical-align: top;
    border-radius: 5px 0px 0px 5px;
    background-color: rgb(225, 225, 225);
    display: inline-block;
    text-align: center;
}

.hints-menu select {
    width: 50%;
    height: 100%;
    border: none;
    text-align: center;
    background-color: white;
    border-radius: 0px 5px 5px 0px;
    display: inline-block;
    vertical-align: top;
}

.sound-icon-container {
    width: 50%;
    height: 100%;
    background-color: white;
    border-radius: 0px 5px 5px 0px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

#sound-on-icon,
#sound-off-icon {
    display: block;
    margin: auto;
    width: 20px;
    height: 100%;
}

#sound-on-icon {
    color: blue;
}

.training-button {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
    border: none;
    border-radius: 5px;
    background: #827717;
    color: white;
    padding: 0px;
    height: 50px;
}

.trainer p {
    font-size: 14px;
}

/* Fit hotkey text in box */
@media (max-width: 1500px), (max-height: 1000px) {
    #hotkey {
        font-size: medium;
        padding-top: 5px;
    }
}

@media (max-height: 875px) {
    
    .trainer-title {
        font-size: x-large;
        font-weight: 750;
    }

    .trainer-container .title-row {
        height: 35px
    }

    .settings {
        padding: 12px;
    }

    .settings p {
        font-size: 14px;
        margin-top: 0;
    }

    .settings > h2 {
        font-size: large;
        margin-bottom: 10px;
    }

    .timer {
        height: 40px;
        width: 75px;
        left: calc(100% - 75px)
    }

    .timer h1 {
        font-size: x-large;
    }

    .game-menu {
        height: 70px;
        margin-bottom: 10px;
    }

    .hints-menu {
        margin-bottom: 10px;
    }

    .hints-menu,
    .sound-menu {
        height: 35px;
    }

    .game-menu p,
    .hints-menu p,
    .sound-menu p {
        margin-top: 9px;
    }

    .training-button {
        height: 34px;
        width: calc(100% - 24px);
    }

    .hotkey-selection p {
        font-size: 14px;
    }

    .trainer-results h2 {
        font-size: large;
    }

    #hotkey {
        font-size: 14px;
        padding-top: 5px;
    }
}

/* Stack cards vertically */
@media (max-width: 975px) {
    .card-container {
        width: 100%;
        height: max-content;
        min-width: 0;
        max-width: 500px;
    }

    .trainer-card {
        display: block;
        width: 100%;
    }

    .trainer-card:first-of-type {
        margin-bottom: 20px;
    }
}

/* Is mobile device */
@media (max-width: 750px) {
    .trainer-container {
        display: none !important;
    }

    #hotkey-editor {
        display: none !important;
    }

    .trainer-instructions-modal {
        display: none !important;
    }

    #screen-too-small {
        display: block;
    }
}
